.button-container.flex-row {
  flex-direction: row;
}

.button-container.flex-col {
  flex-direction: column;
}

.button-container .ant-btn {
  width: 100%; /* Full width for the button */
  /* justify-content: left; */
  padding: 12px 16px; /* Padding to match the style in the image */
  border-radius: 4px;
  margin-bottom: 10px; /* Margin between buttons */
}

/* Archivo CSS */
.card-user {
    width: 300px;
    height: 400px;
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    transition: width 0.3s, height 0.3s;
}

@media (max-width: 768px) {
    .card-user {
        width: 250px;
        height: 350px;
    }
}

@media (max-width: 480px) {
    .card-user {
        width: 200px;
        height: 300px;
    }
}

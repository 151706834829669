.custom-checkbox .ant-checkbox-inner {
    border-color: #E1E1E2;
    border-radius: 8px;
    transition: all 0.3s ease;
    background-color: #F4F4F5;
    margin-right: 6px;
    margin-left: 0px;
    height: 20px;
    width: 20px;
  }
  
  .custom-checkbox .ant-checkbox-inner:hover {
    border-color: #91C3FD !important;
    box-shadow: 0 0 0 4px #91C3FD;
  }
  
  .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0778B1;
    border-color: #0778B1;
  }
  
  .custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
    content: '';
    position: absolute;
    top: 9px;
    left: 10px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .custom-checkbox:hover .ant-checkbox-inner {
    box-shadow: 0 0 0 4px #91C3FD !important;
  }
  
  .custom-checkbox .ant-checkbox-inner:focus-within {
    box-shadow: none;
  }
  